export const pfYellow = {
  50: '#fef8f3',
  100: '#fcede1',
  200: '#fae2cd',
  300: '#f7d6b9',
  400: '#f6cdaa',
  500: '#f4c49b',
  600: '#f3be93',
  700: '#f1b689',
  800: '#efaf7f',
  900: '#eca26d',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#fffdfc',
  A700: '#ffeee3',
  'contrastDefaultColor': 'dark',
};

export const pfRed = {
  50: '#f6e9e6',
  100: '#e9c7bf',
  200: '#daa295',
  300: '#cb7c6b',
  400: '#c0604b',
  500: '#b5442b',
  600: '#ae3e26',
  700: '#a53520',
  800: '#9d2d1a',
  900: '#8d1f10',
  A100: '#ffc5bf',
  A200: '#ff978c',
  A400: '#ff6859',
  A700: '#ff5140',
  'contrastDefaultColor': 'light',
};