import React, { FC } from 'react';
import { MenuItemLink, MenuProps, ReduxState, usePermissions } from 'react-admin';
import { useSelector } from 'react-redux';
import HomeIcon from '../common/icons/HomeIcon';
import { useMediaQuery, Theme } from '@material-ui/core';
import DatabaseIcon from '../common/icons/DatabaseIcon';
import SubMenu from './SubMenu';
import PfReduxState from '../common/types/pf-redux-state.type';
import inflection from 'inflection';
import AdminIcon from '../common/icons/AdminIcon';
import UsersIcon from '../common/icons/UsersIcon';
import ListItemIcon from '../common/icons/ListItemIcon';

const PfMenu: FC<MenuProps> = props => {
  const sidebarIsOpen = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const resources = useSelector((state: PfReduxState) => state.admin.resources);
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );
  const { permissions, loaded } = usePermissions();

  return <>
    <MenuItemLink
      to={`/`}
      primaryText="PF Companion"
      leftIcon={<HomeIcon />}
      sidebarIsOpen={sidebarIsOpen}
    />
    <SubMenu
      icon={<DatabaseIcon />}
      name="Database"
    >
      {Object.values(resources).map(r =>
        r.props.options.showInMenu ??
        (r.props.options.showInMenu ||
          < MenuItemLink
            to={`/${r.props.name}`}
            primaryText={inflection.humanize(r.props.options.label || r.props.name)}
            leftIcon={<ListItemIcon />}
            sidebarIsOpen={sidebarIsOpen}
          />
        )
      )}
    </SubMenu>
    {loaded && +permissions?.scope === 3 && <SubMenu
      icon={<AdminIcon />}
      name="Admin"
    >
      < MenuItemLink
        to={'/users'}
        primaryText={'Users'}
        leftIcon={<UsersIcon />}
        sidebarIsOpen={sidebarIsOpen}
      />
    </SubMenu>}
    {isXSmall && props.logout}
  </>;
}

export default PfMenu;