import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {
  AppBar,
  Notification,
  Sidebar,
  LayoutProps,
  ReduxState,
} from 'react-admin';
import { LoadingIndicator } from './LoadingIndicator';
import PfReduxState from '../common/types/pf-redux-state.type';
import { pfDarkTheme, pfLightTheme } from '../theming/pf-themes';
import Typography from '@material-ui/core/Typography';
import PfToggleThemeButton from '../common/buttons/ToggleThemeButton';
import PfMenu from './Menu';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
    minWidth: '100vw',
  },
  sidebar: {
    backgroundColor: theme.palette.background.paper,
    '& > div > div > a:first-child': {
      height: '48px',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)'
    },
    '& [class^=RaMenu-main]': {
      marginTop: 0,
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    position: 'static',
    '& h6': {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '& div.app-loader': {
      display: 'none'
    },
  },
  main: {
    padding: theme.spacing(2),
    flex: 1,
    boxShadow: 'inset 0px 2px 4px -1px rgba(0,0,0,0.2)',
  },
  loader: {
    position: 'fixed',
    zIndex: 1101,
    top: 0,
    left: 0,
    right: 0
  },
}));

const Layout: FC<LayoutProps> = ({
  children,
  logout,
  title,
}) => {
  const classes = useStyles();
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);

  return <div className={classes.root}>
    <section className={classes.loader}>
      <LoadingIndicator />
    </section>
    <section className={classes.sidebar}>
      <Sidebar classes={{} as any}>
        <PfMenu logout={logout} />
      </Sidebar>
    </section>
    <section className={classes.content}>
      <AppBar color="primary" className={classes.header} title={title} open={open} logout={logout as any}>
        <Typography
          variant="h6"
          id="react-admin-title"
        />
        <PfToggleThemeButton />
      </AppBar>
      <main className={classes.main}>
        {children}
      </main>
    </section>
    <Notification />
  </div>;
};

const ThemedLayout: FC<LayoutProps> = props => {
  const theme = useSelector((state: PfReduxState) => state.theme);
  const themeOptions = theme === 'light' ? pfLightTheme : pfDarkTheme;

  return <ThemeProvider theme={createMuiTheme(themeOptions)}>
    <Layout {...props} />
  </ThemeProvider>;
};

export default ThemedLayout;
