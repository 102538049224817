import React from 'react';
import { SimpleForm } from 'react-admin';

const PfSimpleForm = props => <SimpleForm
  {...props}
  redirect="show"
  warnWhenUnsavedChanges
  variant="standard"
/>;

export default PfSimpleForm;