import React, { FC } from 'react';
import { ListProps, NumberField, TextField } from 'react-admin';
import PfList from '../../common/components/PfList';
import PfNameAndTraitsField from '../../common/fields/PfNameAndTraitsField';

const ItemList: FC<ListProps> = props => (
  <PfList {...props}>
    <PfNameAndTraitsField source="name" />
    <NumberField source="price" />
    <TextField source="priceUnit" />
    <NumberField source="level" />
    <TextField source="source" />
  </PfList>
);

export default ItemList;