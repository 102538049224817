import React from 'react';
import { TextInput } from 'react-admin';
import PfEdit from '../../common/components/PfEdit';
import PfSimpleForm from '../../common/components/PfSimpleForm';
import PfMarkdownInput from '../../common/inputs/PfMarkdownInput';

const BloodlineEdit = props => (
  <PfEdit {...props}>
    <PfSimpleForm>
      <TextInput source="name" />
      <PfMarkdownInput source="body" fullWidth />
      <TextInput source="source" />
    </PfSimpleForm>
  </PfEdit>
);

export default BloodlineEdit;