import React from 'react';
import { Route } from 'react-router-dom';
import Database from './pages/Database';
import Login from './pages/Login';

const customRoutes = [
  <Route exact path="/login-page" component={Login} noLayout />,
  <Route exact path="/db" component={Database} />,
]

export default customRoutes;