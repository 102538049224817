import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';
import { Title } from 'react-admin';

const Dashboard: FC<any> = props => {
  return <Card>
    <Title title="Welcome!" />
    <CardContent>
      <Typography variant="body1">
        Welcome to the Pathfinder 2 Companion Website!
      </Typography>
    </CardContent>
  </Card>;
};

export default Dashboard;