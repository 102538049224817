import { AuthProvider } from "react-admin";
import { getEnvApiUrl } from "./dataProvider";

export const authKey = 'pf2-auth';

const getAndDecodeJwt = () => {
  const token = localStorage.getItem(authKey);

  if (!token) {
    throw new Error('403');
  }

  const base64Payload = token?.split('.')[1];
  const payload = JSON.parse(atob(base64Payload!));
  return payload;
}

const authProvider: AuthProvider = {
  login: async ({ email, password, redirect }) => {
    const url = getEnvApiUrl() as string;
    const request = new Request(`${url}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    const response = await fetch(request);

    if (response.status < 200 || response.status >= 300) {
      return Promise.reject(response);
    }

    const { token } = await response.json();
    localStorage.setItem(authKey, token as string);
  },
  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem(authKey);
      return Promise.reject();
    }

    return Promise.resolve();

  },
  checkAuth: params => {
    return Promise.resolve();
  },
  logout: async ({ redirect }) => {
    localStorage.removeItem(authKey);
    return redirect ?? 'login-page';
  },
  getPermissions: async params => getAndDecodeJwt(),
  getIdentity: async () => {
    try {
      const token = getAndDecodeJwt();

      return {
        id: token.id,
        fullName: token.email
      }
    } catch (e) {
      return {
        id: null,
        fullName: null,
      }
    }
  },
}

export default authProvider;