import { ResourceProps } from 'react-admin';
import FeatEdit from './FeatEdit';
import FeatList from './FeatList';
import FeatShow from './FeatShow';

const feats: ResourceProps = {
  name: 'feats',
  list: FeatList,
  edit: FeatEdit,
  show: FeatShow
};

export default feats;