import React, { FC } from 'react';
import { Edit, TitleProps, TopToolbar } from 'react-admin';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles";
import RedirectButtonField from '../buttons/RedirectButtonField';
import ShowIcon from '../icons/ShowIcon';
import inflection from 'inflection';

const PfEditTitle: FC<TitleProps> = props => {
  const { singularize, humanize } = inflection;
  const inflectedTitle = singularize(humanize(props.title as string));
  return <span>Edit {inflectedTitle}: {props.record?.name}</span>;
};

const useToolbarStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.background.default,
  }
}));

const PfEditActions: FC<any> = props => {
  const classes = useToolbarStyles();

  return <TopToolbar className={classes.toolbar}>
    <RedirectButtonField
      icon={<ShowIcon />}
      redirectTo="show"
      color="primary"
      variant="contained"
      basePath={props.basePath}
      record={props.data}
    />
  </TopToolbar>;
};

const PfEdit: FC<any> = props => {
  return <Edit
    {...props}
    actions={<PfEditActions />}
    title={<PfEditTitle title={props.resource} />}
  />;
};

export default PfEdit;