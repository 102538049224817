import { ResourceProps } from 'react-admin';
import SpellEdit from './SpellEdit';
import SpellList from './SpellList';
import SpellShow from './SpellShow';

const spells: ResourceProps = {
  name: 'spells',
  list: SpellList,
  edit: SpellEdit,
  show: SpellShow
};

export default spells;