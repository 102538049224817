import React, { FC } from 'react';
import { ListProps, TextField } from 'react-admin';
import PfList from '../../common/components/PfList';
import PfNameAndTraitsField from '../../common/fields/PfNameAndTraitsField';

const PlaneList: FC<ListProps> = props => (
  <PfList {...props}>
    <PfNameAndTraitsField source="name" />
    <TextField source="source" />
  </PfList>
);

export default PlaneList;