import React from 'react';
import { NumberField, SimpleShowLayout, TextField } from 'react-admin';
import PfShow from '../../common/components/PfShow';
import PfMarkdownField from '../../common/fields/PfMarkdownField';
import PfNameAndTraitsField from '../../common/fields/PfNameAndTraitsField';

const WeaponShow = props => (
  <PfShow {...props}>
    <SimpleShowLayout>
      <PfNameAndTraitsField source="name" />
      <TextField source="group" />
      <TextField source="category" />
      <TextField source="damageDie" />
      <TextField source="price" />
      <TextField source="priceUnit" />
      <TextField source="damageType" />
      <TextField source="bulk" />
      <TextField source="hands" />
      <NumberField source="range" />
      <NumberField source="reload" />
      <PfMarkdownField source="body" addLabel={true} />
      <TextField source="source" />
    </SimpleShowLayout>
  </PfShow>
);

export default WeaponShow;