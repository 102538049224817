import React, { cloneElement, FC } from 'react';
import {
  Datagrid,
  Filter,
  List,
  ListActionsProps,
  ListProps,
  sanitizeListRestProps,
  TextInput,
  TopToolbar,
  useListContext,
  Pagination,
  PaginationActions,
  SimpleList,
  SimpleListProps,
  usePermissions,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import makeStyles from '@material-ui/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme } from '@material-ui/core/styles';
import RedirectButtonField from '../buttons/RedirectButtonField';
import AddIcon from '../icons/AddIcon';
import inflection from 'inflection';
import EditIcon from '../icons/EditIcon';
import ArrowRightIcon from '../icons/ArrowRightIcon';
import PfNameAndTraitsField from '../fields/PfNameAndTraitsField';

const Pf2ListActions: FC<ListActionsProps> = props => {
  const {
    className,
    exporter,
    filters,
    ...rest
  } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    showFilter,
  } = useListContext();
  const { loaded, permissions } = usePermissions();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      {hasCreate && loaded && +permissions?.scope >= 2 && <RedirectButtonField
        basePath={basePath}
        redirectTo="create"
        variant="contained"
        color="primary"
        icon={<AddIcon />}
      />}
    </TopToolbar>
  );
};


const useListStyles = makeStyles((theme: Theme) => ({
  content: {
    boxShadow: 'none'
  },
  actions: {
    paddingRight: theme.spacing(2)
  }
}));

const PF2ListFilters: FC<any> = props => {
  return <Filter {...props} variant="standard">
    <TextInput source="q" label="Search" placeholder="Search by name, source, or trait" alwaysOn resettable />
  </Filter>;
};

const PfPaginationActions = props =>
  <PaginationActions
    {...props}
    color="default"
  />;

const useStyles = makeStyles((theme: Theme) => ({
  listCard: {
    paddingTop: '0px',
    paddingBottom: '0px !important',
  },
  editButtonCellStyles: {
    textAlign: 'end'
  }
}));

const useDatagridOverrideStyles = makeStyles((theme: Theme) => ({
  enforceTheme: {
    '& th': {
      backgroundColor: theme.palette.background.paper
    }
  }
}));

type PfListProps = ListProps &
{
  cardTitle?: string;
  simpleListProps?: Omit<SimpleListProps, 'linkType' | 'rightIcon'>
}

const PfList: FC<PfListProps> = props => {
  const listProps = props as ListProps;
  const listClasses = useListStyles();
  const classes = useStyles();
  const dgClasses = useDatagridOverrideStyles();
  const { cardTitle = props.resource } = props;
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const simpleListDefaults = {
    primaryText: record => <PfNameAndTraitsField source="name" record={record} />,
    ...props.simpleListProps
  }

  return (
    <Card>
      <CardHeader title={inflection.humanize(cardTitle!)} />
      <CardContent className={classes.listCard}>
        <List
          {...listProps}
          actions={<Pf2ListActions className={listClasses.actions} />}
          bulkActionButtons={false}
          classes={listClasses}
          exporter={false}
          filters={<PF2ListFilters />}
          pagination={<Pagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            actions={PfPaginationActions}
          />}
        >
          {isSmall ?
            <SimpleList
              {...simpleListDefaults}
              linkType="show"
              rightIcon={() => <ArrowRightIcon />}
            />
            :
            <Datagrid
              className={dgClasses.enforceTheme}
              rowClick={props.hasShow ? 'show' : ''}
              rowStyle={() => ({
                height: '48px'
              })}
              optimized
            >
              {props.children}
              {props.hasEdit && +props.permissions?.scope >= 2 && <RedirectButtonField
                icon={<EditIcon />}
                variant="contained"
                color="primary"
                redirectTo="edit"
                cellClassName={classes.editButtonCellStyles}
              />}
            </Datagrid>
          }
        </List>
      </CardContent>
    </Card>
  );
};

export default PfList;