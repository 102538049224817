import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  breakLines: {
    whiteSpace: 'break-spaces'
  }
});

const PfMarkdown = props => {
  const classes = useStyles();
  
  return <ReactMarkdown
    {...props}
    className={classes.breakLines}
    plugins={[
      gfm
    ]}
  />
};

export default PfMarkdown;