import React from 'react';
import { useSelector } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ReduxState } from 'react-admin';

export const LoadingIndicator = () => {
  const loading = useSelector((state: ReduxState) => state.admin.loading > 0);
  return loading ? (
    <LinearProgress color="secondary" />
  ) : null;
};
