import { ResourceProps } from 'react-admin';
import BackgroundEdit from './BackgroundEdit';
import BackgroundList from './BackgroundList';
import BackgroundShow from './BackgroundShow';

const backgrounds: ResourceProps = {
  name: 'backgrounds',
  list: BackgroundList,
  edit: BackgroundEdit,
  show: BackgroundShow,
};

export default backgrounds;