import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import React, { FC } from 'react';
import { Title } from 'react-admin';

const Database: FC<any> = props => {
  return <Card>
    <Title title="Database" />
    <CardContent>Welcome to the Pathfinder 2 database click something!</CardContent>
    <CardContent>Welcome to the Pathfinder 2 database click something!</CardContent>
    <CardContent>Welcome to the Pathfinder 2 database click something!</CardContent>
    <CardContent>Welcome to the Pathfinder 2 database click something!</CardContent>
    <CardContent>Welcome to the Pathfinder 2 database click something!</CardContent>
  </Card>;
};

export default Database;