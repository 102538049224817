import React, { FC } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import { Button, ButtonProps } from "react-admin";

const useStyles = makeStyles((theme: Theme) => ({
  pfButton: {
    minWidth: '120px',
    [theme.breakpoints.down('sm')]: {
      minWidth: 0
    }
  },
  noIcon: {
    '& .MuiButton-label > span': {
      paddingLeft: 0
    }
  }
}));

const PfButton: FC<ButtonProps> = props => {
  const classes = useStyles();
  const { icon } = props;

  return <Button
    {...props}
    className={`${classes.pfButton} ${!icon && classes.noIcon} ${props.className}`}
  >
    {icon && icon}
  </Button>;
};

export default PfButton;