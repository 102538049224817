import React, { FC } from 'react';
import { FieldProps } from 'react-admin';
import Chip from '@material-ui/core/Chip';
import makeStyles from '@material-ui/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  chipSpan: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  }
}))

const PfChipField: FC<FieldProps> = props => {
  const { record, source } = props;
  const chips = record![source!];
  const classes = useStyles();

  if (!chips) {
    return null;
  }

  return <span>{
    chips.map((c, i) =>
      <Chip
        key={i}
        label={c}
        color="primary"
        className={classes.chipSpan}
      />
    )
  }</span>
}

export default PfChipField;