import { ResourceProps } from 'react-admin';
import MonsterEdit from './MonsterEdit';
import MonsterList from './MonsterList';
import MonsterShow from './MonsterShow';

const monsters: ResourceProps = {
  name: 'monsters',
  list: MonsterList,
  edit: MonsterEdit,
  show: MonsterShow,
};

export default monsters;