import React, { FC, forwardRef } from 'react';
import { useGetIdentity, useLogout } from 'react-admin';
import { useHistory } from 'react-router-dom';
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";
import LoginIcon from '../common/icons/LoginIcon';
import LogoutIcon from '../common/icons/LogoutIcon';

type LogoutProps = {};

const LogoutButton: FC<
  LogoutProps &
  MenuItemProps<'li'>
> = forwardRef((props, ref) => {
  const { identity } = useGetIdentity();
  const history = useHistory();
  const logout = useLogout();

  const loggedIn = identity?.id;
  const redirect = history.location.pathname;

  return <MenuItem
    ref={ref}
    onClick={() => {
      loggedIn ? logout({ redirect }) : history.push(
        `/login-page?redirect=${redirect}`
      )
    }}
  >
    {loggedIn ? <LogoutIcon /> : <LoginIcon />}
    {loggedIn ? 'Logout' : 'Login'}
  </MenuItem>
})

export default LogoutButton;