import { ResourceProps } from 'react-admin';
import ConditionEdit from './ConditionEdit';
import ConditionList from './ConditionList';
import ConditionShow from './ConditionShow';

const conditions: ResourceProps = {
  name: 'conditions',
  list: ConditionList,
  edit: ConditionEdit,
  show: ConditionShow,
};

export default conditions;