import React from 'react';
import { Admin, Resource } from "react-admin";
import classes from './entities/classes';
import feats from './entities/feats';
import skills from './entities/skills';
import spells from './entities/spells';
import ThemedLayout from './layout/Layout';
import Dashboard from './pages/Dashboard';
import themeSlice from './redux-slices/theme.slice';
import traits from './entities/traits';
import items from './entities/items';
import weapons from './entities/weapons';
import actions from './entities/actions';
import backgrounds from './entities/backgrounds';
import bloodlines from './entities/bloodlines';
import deites from './entities/deites';
import foci from './entities/foci';
import heritages from './entities/heritages';
import planes from './entities/planes';
import customRoutes from './custom-routes';
import dataProvider from './providers/dataProvider';
import authProvider from './providers/authProvider';
import users from './entities/users';
import Login from './pages/Login';
import LogoutButton from './layout/LogoutButton';
import entityTraits from './entities/entity-traits';
import conditions from './entities/conditions';
import monsters from './entities/monsters';

function App() {
  return <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
    loginPage={Login}
    logoutButton={LogoutButton}
    layout={ThemedLayout}
    dashboard={Dashboard}
    customReducers={{
      [themeSlice.name]: themeSlice.reducer
    }}
    customRoutes={customRoutes}
  >
    <Resource {...entityTraits} />
    <Resource {...actions} />
    <Resource {...backgrounds} />
    <Resource {...bloodlines} />
    <Resource {...classes} />
    <Resource {...conditions} />
    <Resource {...deites} />
    <Resource {...feats} />
    <Resource {...foci} />
    <Resource {...heritages} />
    <Resource {...items} />
    <Resource {...monsters} />
    <Resource {...planes} />
    <Resource {...skills} />
    <Resource {...spells} />
    <Resource {...traits} />
    <Resource {...users} />
    <Resource {...weapons} />
  </Admin>;
}

export default App;
