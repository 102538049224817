import { ResourceProps } from 'react-admin';
import UserEdit from './UserEdit';
import UserList from './UserList';
import UserShow from './UserShow';
import UserCreate from './UserCreate';

const users: ResourceProps = {
  name: 'users',
  list: UserList,
  edit: UserEdit,
  show: UserShow,
  create: UserCreate,
  options: {
    showInMenu: false
  }
};

export default users;