import React from 'react';
import { SimpleShowLayout, TextField } from 'react-admin';
import PfShow from '../../common/components/PfShow';
import PfMarkdownField from '../../common/fields/PfMarkdownField';

const BloodlineShow = props => (
  <PfShow {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <PfMarkdownField source="body" addLabel={true} />
      <TextField source="source" />
    </SimpleShowLayout>
  </PfShow>
);

export default BloodlineShow;