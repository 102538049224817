import React, { FC } from 'react';
import { NumberField, ShowProps, SimpleShowLayout, TextField } from 'react-admin';
import PfShow from '../../common/components/PfShow';
import PfActionsField from '../../common/fields/PfActionsField';
import PfMarkdownField from '../../common/fields/PfMarkdownField';
import PfNameAndTraitsField from '../../common/fields/PfNameAndTraitsField';

const FeatShow: FC<ShowProps> = props => (
  <PfShow {...props}>
    <SimpleShowLayout>
      <PfNameAndTraitsField source="name" />
      <NumberField source="level" />
      <TextField source="subtype" />
      <PfActionsField source="actions" />
      <PfMarkdownField source="body" addLabel={true} />
      <TextField source="source" />
    </SimpleShowLayout>
  </PfShow>
);

export default FeatShow;