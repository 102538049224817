import React, { FC } from 'react';
import { BooleanField, NumberField, ShowProps, SimpleShowLayout, TextField } from 'react-admin';
import PfShow from '../../common/components/PfShow';
import useRequiredScope from '../../common/hooks/useRequiredScope';

const UserShow: FC<ShowProps> = props => {
  const isAdmin = useRequiredScope(3);

  return isAdmin ?
  <PfShow {...props}>
    <SimpleShowLayout>
      <TextField source="email" />
      <NumberField source="scope" />
      <BooleanField source="disabled" />
    </SimpleShowLayout>
  </PfShow> : null;
}

export default UserShow;