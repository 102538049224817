import React from 'react';
import { BooleanInput, NumberInput, TextInput } from 'react-admin';
import PfEdit from '../../common/components/PfEdit';
import PfSimpleForm from '../../common/components/PfSimpleForm';
import useRequiredScope from '../../common/hooks/useRequiredScope';

const UserEdit = props => {
  const isAdmin = useRequiredScope(3);

  return isAdmin ?
    <PfEdit {...props}>
      <PfSimpleForm>
        <TextInput source="email" />
        <NumberInput source="scope" />
        <BooleanInput source="disabled" />
      </PfSimpleForm>
    </PfEdit> : null;
}

export default UserEdit;