import React from 'react';
import { NumberInput, TextInput } from 'react-admin';
import PfEdit from '../../common/components/PfEdit';
import PfSimpleForm from '../../common/components/PfSimpleForm';
import PfMarkdownInput from '../../common/inputs/PfMarkdownInput';

const WeaponEdit = props => (
  <PfEdit {...props}>
    <PfSimpleForm>
      <TextInput source="name" />
      <TextInput source="traits" />
      <TextInput source="group" />
      <TextInput source="category" />
      <TextInput source="damageDie" />
      <TextInput source="price" />
      <TextInput source="priceUnit" />
      <TextInput source="damageType" />
      <TextInput source="bulk" />
      <TextInput source="hands" />
      <NumberInput source="range" />
      <NumberInput source="reload" />
      <PfMarkdownInput source="body" fullWidth />
      <TextInput source="source" />
    </PfSimpleForm>
  </PfEdit>
);

export default WeaponEdit;