import React, { FC, useState } from 'react';
import { InputProps, TextInput } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PfMarkdown from '../components/PfMarkdown';

const useStyles = makeStyles(theme => ({
  doubleView: {
    display: 'flex',
    '& > div:nth-child(1)': {
      width: '40%',
      paddingRight: theme.spacing(2)
    },
    '& > div:nth-child(2)': {
      width: '60%',
      paddingLeft: theme.spacing(2)
    }
  }
}));

const PfMarkdownInput: FC<InputProps> = props => {
  const [value, setValue] = useState(props.record[props.source]);
  const classes = useStyles();

  return <div className={classes.doubleView}>
    <div>
      <TextInput
        {...props}
        fullWidth
        multiline
        onChange={e => setValue(e.target.value)}
      />
    </div>
    <div>
      <Typography>
        <PfMarkdown children={value} />
      </Typography>
    </div>
  </div>;
};

export default PfMarkdownInput;