import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
import { pfRed, pfYellow } from './pf-colors';

const overrides: Overrides = {
  MuiCard: {
    root: {
      borderRadius: 0
    }
  },
  MuiButton: {
    root: {
      borderRadius: 0
    }
  },
};

export const pfLightTheme: ThemeOptions = {
  palette: {
    type: 'light',
    primary: pfRed,
    secondary: pfYellow,
    background: {
      default: pfYellow[50],
      // paper: pfYellow[50],
    }
  },
  overrides,
};

export const pfDarkTheme: ThemeOptions = {
  palette: {
    type: 'dark',
    primary: pfYellow,
    secondary: pfRed,
  },
  overrides,
};
