import React, { FC } from 'react';
import { FieldProps } from 'react-admin';
import PfMarkdown from '../components/PfMarkdown';

const PfMarkdownField: FC<FieldProps> = props => {
  const { record, source } = props;
  const value = record![source!];
  return <PfMarkdown children={value} />;
};

export default PfMarkdownField;