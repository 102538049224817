import React, { FC } from 'react';
import { ButtonProps } from "react-admin";
import { useDispatch, useSelector } from 'react-redux';
import themeSlice from '../../redux-slices/theme.slice';
import DarkThemeIcon from '../icons/DarkThemeIcon';
import LightThemeIcon from '../icons/LightThemeIcon';
import PfReduxState from '../types/pf-redux-state.type';
import IconButton from '@material-ui/core/IconButton';

const PfToggleThemeButton: FC<ButtonProps> = props => {
  const dispatch = useDispatch();
  const theme = useSelector((state: PfReduxState) => state.theme);

  return <IconButton color="inherit"
    onClick={() => {
      dispatch(themeSlice.actions.toggle());
    }}>
    {theme === 'light' ? <DarkThemeIcon /> : <LightThemeIcon />}
  </IconButton>;
};

export default PfToggleThemeButton;