import React, { FC } from 'react';
import { ButtonProps, FieldProps } from 'react-admin';
import { useHistory } from "react-router-dom";
import PfButton from './PfButton';

const RedirectButtonField: FC<
  FieldProps &
  ButtonProps &
  {
    redirectTo?: 'edit' | 'show' | 'create' | '';
  }
> = props => {
  const history = useHistory();
  const { basePath, record, redirectTo = '' } = props;
  const redirectRoute =
    `${basePath}/${record?.id ? `${record.id}/` : ''}${redirectTo}`;

  return <PfButton
    color={props.color ?? 'default'}
    icon={props.icon}
    label={props.label || redirectTo}
    onClick={e => {
      e.stopPropagation();
      history.push(redirectRoute);
    }}
    variant={props.variant ?? 'text'}
  />;
};

export default RedirectButtonField;