import React from 'react';
import { TextInput } from 'react-admin';
import PfEdit from '../../common/components/PfEdit';
import PfSimpleForm from '../../common/components/PfSimpleForm';
import PfActionsInput from '../../common/inputs/PfActionsInput';
import PfMarkdownInput from '../../common/inputs/PfMarkdownInput';

const ActionEdit = props => (
  <PfEdit {...props}>
    <PfSimpleForm>
      <TextInput source="name" />
      <TextInput source="traits" />
      <PfActionsInput source="actions" addLabel={true} />
      <PfMarkdownInput source="body" fullWidth />
      <TextInput source="source" />
    </PfSimpleForm>
  </PfEdit>
);

export default ActionEdit;