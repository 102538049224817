import { ResourceProps } from 'react-admin';
import WeaponEdit from './WeaponEdit';
import WeaponList from './WeaponList';
import WeaponShow from './WeaponShow';

const weapons: ResourceProps = {
  name: 'weapons',
  list: WeaponList,
  edit: WeaponEdit,
  show: WeaponShow,
};

export default weapons;