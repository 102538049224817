import { ResourceProps } from 'react-admin';
import ItemEdit from './ItemEdit';
import ItemList from './ItemList';
import ItemShow from './ItemShow';

const items: ResourceProps = {
  name: 'items',
  list: ItemList,
  edit: ItemEdit,
  show: ItemShow,
};

export default items;