import { ResourceProps } from 'react-admin';
import PlaneEdit from './PlaneEdit';
import PlaneList from './PlaneList';
import PlaneShow from './PlaneShow';

const planes: ResourceProps = {
  name: 'planes',
  list: PlaneList,
  edit: PlaneEdit,
  show: PlaneShow
};

export default planes;