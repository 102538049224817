import { ResourceProps } from 'react-admin';
import BloodlineEdit from './BloodlineEdit';
import BloodlineList from './BloodlineList';
import BloodlineShow from './BloodlineShow';

const bloodlines: ResourceProps = {
  name: 'bloodlines',
  list: BloodlineList,
  edit: BloodlineEdit,
  show: BloodlineShow,
};

export default bloodlines;