import React from 'react';
import { NumberField, SimpleShowLayout, TextField } from 'react-admin';
import PfShow from '../../common/components/PfShow';
import PfMarkdownField from '../../common/fields/PfMarkdownField';
import PfNameAndTraitsField from '../../common/fields/PfNameAndTraitsField';

const ItemShow = props => (
  <PfShow {...props}>
    <SimpleShowLayout>
      <PfNameAndTraitsField source="name" />
      <NumberField source="price" />
      <TextField source="priceUnit" />
      <NumberField source="level" />
      <PfMarkdownField source="body" addLabel={true} />
      <TextField source="source" />
    </SimpleShowLayout>
  </PfShow>
);

export default ItemShow;