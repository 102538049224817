import React from 'react';
import { SimpleShowLayout, TextField } from 'react-admin';
import PfShow from '../../common/components/PfShow';
import PfActionsField from '../../common/fields/PfActionsField';
import PfMarkdownField from '../../common/fields/PfMarkdownField';
import PfNameAndTraitsField from '../../common/fields/PfNameAndTraitsField';

const ActionShow = props => (
  <PfShow {...props}>
    <SimpleShowLayout>
      <PfNameAndTraitsField source="name" label="Name" addLabel={true} />
      <PfActionsField source="actions" />
      <PfMarkdownField source="body" addLabel={true} />
      <TextField source="source" />
    </SimpleShowLayout>
  </PfShow>
);

export default ActionShow;