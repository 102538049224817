import { ResourceProps } from 'react-admin';
import TraitEdit from './TraitEdit';
import TraitList from './TraitList';
import TraitShow from './TraitShow';

const traits: ResourceProps = {
  name: 'traits',
  list: TraitList,
  edit: TraitEdit,
  show: TraitShow,
};

export default traits;