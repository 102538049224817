import React, { FC } from 'react';
import { Show, ShowActionsProps, TitleProps, TopToolbar, usePermissions } from 'react-admin';
import RedirectButtonField from '../buttons/RedirectButtonField';
import BackIcon from '../icons/BackIcon';
import EditIcon from '../icons/EditIcon';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core/styles";
import inflection from 'inflection';
import { useHistory } from 'react-router-dom';
import PfButton from '../buttons/PfButton';

const PfShowTitle: FC<TitleProps> = props => {
  const { singularize, humanize } = inflection;
  const inflectedTitle = singularize(humanize(props.title as string));
  return <span>{inflectedTitle}: {props.record?.name}</span>;
};

const useToolbarStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.default,
  }
}));

const PfShowActions: FC<ShowActionsProps> = props => {
  const classes = useToolbarStyles();
  const { permissions } = usePermissions();
  const history = useHistory();

  return <TopToolbar className={classes.toolbar}>
    <PfButton
      color="primary"
      icon={<BackIcon />}
      label="go back"
      onClick={e => {
        e.stopPropagation();
        history.goBack();
      }}
      variant={'text'}
    />
    {permissions?.scope >= 2 && <RedirectButtonField
      label="edit"
      icon={<EditIcon />}
      redirectTo="edit"
      color="primary"
      variant="contained"
      basePath={props.basePath}
      record={props.data}
    />}
  </TopToolbar>;
};

const PfShow: FC<any> = props => {
  return <Show
    {...props}
    actions={<PfShowActions />}
    title={<PfShowTitle title={props.resource} />}
  />;
};

export default PfShow;