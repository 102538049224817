import { ResourceProps } from 'react-admin';
import ActionEdit from './ActionEdit';
import ActionList from './ActionList';
import ActionShow from './ActionShow';

const actions: ResourceProps = {
  name: 'actions',
  list: ActionList,
  edit: ActionEdit,
  show: ActionShow,
};

export default actions;