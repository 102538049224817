import React, { FC } from 'react';
import { FieldProps } from 'react-admin';

const PfActionsField: FC<FieldProps> = props => {
  const { record, source } = props;
  const actions = record![source!];

  if (!actions) return null;

  return actions ?
    <i className={`pf2 pf2-action-${actions}`}></i>
    : null;
}

export default PfActionsField;