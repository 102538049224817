import React, { FC } from 'react';
import { SelectInput, InputProps } from 'react-admin';

const PfActionsInput: FC<InputProps> = props => {
  return <SelectInput {...props} choices={[
    { id: '-1', name: 'None' },
    { id: '0', name: 'Free' },
    { id: '1', name: '1 Action' },
    { id: '2', name: '2 Actions' },
    { id: '3', name: '3 Actions' },
    { id: 'r', name: 'Reaction' }
  ]}
  />
}

export default PfActionsInput;