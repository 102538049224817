import React, { FC } from 'react';
import { BooleanField, ListProps, NumberField, TextField } from 'react-admin';
import PfList from '../../common/components/PfList';
import useRequiredScope from '../../common/hooks/useRequiredScope';

const UserList: FC<ListProps> = props => {
  const isAdmin = useRequiredScope(3);

  return isAdmin ?
    <PfList {...props}>
      <TextField source="email" />
      <NumberField source="scope" />
      <BooleanField source="disabled" />
    </PfList> : null;
}

export default UserList;