import { createSlice } from '@reduxjs/toolkit';

const themeLocalStorageKey = 'pf2-theme';
const getThemeFromLocalStorage = () => localStorage.getItem(themeLocalStorageKey);
const saveThemeToLocalStorage = (theme: string) =>
  localStorage.setItem(themeLocalStorageKey, theme);

const themeSlice = createSlice({
  name: 'theme',
  initialState: getThemeFromLocalStorage(),
  reducers: {
    toggle: state => {
      const theme = state === 'light' ? 'dark' : 'light';
      saveThemeToLocalStorage(theme);
      return theme;
    }
  }
});

export default themeSlice;