import React, { FC } from 'react';
import { EditProps, TextInput } from 'react-admin';
import PfEdit from '../../common/components/PfEdit';
import PfSimpleForm from '../../common/components/PfSimpleForm';
import PfMarkdownInput from '../../common/inputs/PfMarkdownInput';

const HeritageEdit: FC<EditProps> = props => (
  <PfEdit {...props}>
    <PfSimpleForm>
      <TextInput source="name" />
      <TextInput source="traits" />
      <PfMarkdownInput source="body" fullWidth />
      <TextInput source="source" />
    </PfSimpleForm>
  </PfEdit>
);


export default HeritageEdit;