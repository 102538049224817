import React, { FC } from 'react';
import { NumberField, ShowProps, SimpleShowLayout, TextField } from 'react-admin';
import PfShow from '../../common/components/PfShow';
import PfActionsField from '../../common/fields/PfActionsField';
import PfChipField from '../../common/fields/PfChipField';
import PfMarkdownField from '../../common/fields/PfMarkdownField';
import PfNameAndTraitsField from '../../common/fields/PfNameAndTraitsField';

const SpellShow: FC<ShowProps> = props => (
  <PfShow {...props}>
    <SimpleShowLayout>
      <PfNameAndTraitsField source="name" />
      <NumberField source="level" />
      <PfChipField source="traditions" addLabel={true} />
      <PfChipField source="cast" addLabel={true} />
      <PfActionsField source="actions" />
      <TextField source="range" />
      <PfChipField source="heighten" addLabel={true} />
      <PfMarkdownField source="body" addLabel={true} />
      <TextField source="source" />
    </SimpleShowLayout>
  </PfShow>
);

export default SpellShow;