import { ResourceProps } from 'react-admin';
import FocusEdit from './FocusEdit';
import FocusList from './FocusList';
import FocusShow from './FocusShow';

const foci: ResourceProps = {
  name: 'foci',
  list: FocusList,
  edit: FocusEdit,
  show: FocusShow,
  options: {
    label: 'Foci'
  }
};

export default foci;