import React, { FC } from 'react';
import { ListProps, NumberField, TextField } from 'react-admin';
import PfList from '../../common/components/PfList';
import PfNameAndTraitsField from '../../common/fields/PfNameAndTraitsField';

const FeatList: FC<ListProps> = props => (
  <PfList
    {...props}
    simpleListProps={{
      secondaryText: record => `Level ${record.level}${record.subtype ? ` ${record.subtype} feat` : ''}`
    }}
  >
    <PfNameAndTraitsField source="name" />
    <NumberField source="level" />
    <TextField source="subtype" />
    <TextField source="source" />
  </PfList>
);

export default FeatList;