import { ResourceProps } from 'react-admin';
import SkillEdit from './SkillEdit';
import SkillList from './SkillList';
import SkillShow from './SkillShow';

const skills: ResourceProps = {
  name: 'skills',
  list: SkillList,
  edit: SkillEdit,
  show: SkillShow
};

export default skills;