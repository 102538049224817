import { ResourceProps } from 'react-admin';
import ClassEdit from './ClassEdit';
import ClassList from './ClassList';
import ClassShow from './ClassShow';

const classes: ResourceProps = {
  name: 'classes',
  list: ClassList,
  edit: ClassEdit,
  show: ClassShow,
};

export default classes;