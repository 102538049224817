import simpleRestProvider from "ra-data-simple-rest";
import { fetchUtils } from 'react-admin';
import { authKey } from "./authProvider";

export const getEnvApiUrl = () => {
  // stupid prefix is used since create-react-app deletes all env vars without it
  switch (process.env.REACT_APP_ENV) {
    case 'dev': {
      return 'http://localhost:3001';
    }
    case 'prod': {
      return 'https://api.pf2companion.com';
    }
  }
}

const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem(authKey)!;
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(getEnvApiUrl()!, httpClient);

export default dataProvider;
