import React, { FC } from 'react';
import { ListProps, NumberField, TextField } from 'react-admin';
import PfList from '../../common/components/PfList';
import PfActionsField from '../../common/fields/PfActionsField';
import PfChipField from '../../common/fields/PfChipField';
import PfNameAndTraitsField from '../../common/fields/PfNameAndTraitsField';

const SpellList: FC<ListProps> = props => (
  <PfList {...props}>
    <PfNameAndTraitsField source="name" />
    <NumberField source="level" />
    <PfChipField source="traditions" />
    <PfChipField source="cast" />
    <PfActionsField source="actions" />
    <TextField source="range" />
    <TextField source="source" />
  </PfList>
);

export default SpellList;