import React from 'react';
import { Create, NumberInput, PasswordInput, TextInput } from 'react-admin';
import PfSimpleForm from '../../common/components/PfSimpleForm';
import useRequiredScope from '../../common/hooks/useRequiredScope';

const UserCreate = props => {
  const isAdmin = useRequiredScope(3);

  return isAdmin ? 
    <Create {...props}>
      <PfSimpleForm>
        <TextInput source="email" />
        <NumberInput source="scope" />
        <PasswordInput source="password" />
      </PfSimpleForm>
    </Create> : null;
}

export default UserCreate;