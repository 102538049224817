import { ResourceProps } from 'react-admin';
import HeritageEdit from './HeritageEdit';
import HeritageList from './HeritageList';
import HeritageShow from './HeritageShow';

const heritages: ResourceProps = {
  name: 'heritages',
  list: HeritageList,
  edit: HeritageEdit,
  show: HeritageShow
};

export default heritages;