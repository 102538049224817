import React, { FC, useState } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import PfReduxState from '../common/types/pf-redux-state.type';

const useStyles = makeStyles(theme => ({
  icon: { minWidth: theme.spacing(5) },
  sidebarIsOpen: {
    '& a': {
      paddingLeft: theme.spacing(4),
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
  sidebarIsClosed: {
    '& a': {
      paddingLeft: theme.spacing(2),
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
}));

const SubMenu: FC<any> = props => {
  const classes = useStyles();
  const sidebarOpen = useSelector((state: PfReduxState) => state.admin.ui.sidebarOpen);
  const [isOpen, setIsOpen] = useState(false);

  const header = (
    <MenuItem button onClick={() => setIsOpen(!isOpen)}>
      <ListItemIcon >
        {isOpen ? <ExpandMore /> : props.icon}
      </ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {props.name}
      </Typography>
    </MenuItem>
  );

  return <>
    {sidebarOpen ?
      header
      :
      <Tooltip title={props.name} placement="right">
        {header}
      </Tooltip>
    }
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <List className={sidebarOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed}>
        {props.children}
      </List>
    </Collapse>
  </>
}

export default SubMenu;