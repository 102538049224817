import React, { FC } from 'react';
import { ListProps, TextField } from 'react-admin';
import PfList from '../../common/components/PfList';
import PfActionsField from '../../common/fields/PfActionsField';
import PfNameAndTraitsField from '../../common/fields/PfNameAndTraitsField';

const ActionList: FC<ListProps> = props => (
  <PfList 
  {...props}
  simpleListProps={{
    secondaryText: record => record.actions
  }}
  >
    <PfNameAndTraitsField source="name" label="Name" addLabel={true} />
    <PfActionsField source="actions" />
    <TextField source="source" />
  </PfList>
);

export default ActionList;