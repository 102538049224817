import React, { FC, useState } from 'react'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { ThemeProvider } from '@material-ui/styles';
import makeStyles from "@material-ui/core/styles/makeStyles";
import createMuiTheme, { Theme } from "@material-ui/core/styles/createMuiTheme";
import { useSelector } from 'react-redux';
import PfReduxState from '../common/types/pf-redux-state.type';
import { pfDarkTheme, pfLightTheme } from '../theming/pf-themes';
import { Notification, useLogin, useNotify } from 'react-admin';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
  },
  card: {
    height: '300px',
    width: '500px',
    marginTop: '15vh',
    maxWidth: '80vw',
    maxHeight: '60vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > .MuiFormControl-root': {
      marginBottom: theme.spacing(3),
    }
  },
}))

const Login: FC<any> = props => {
  const classes = useStyles();
  const history = useHistory();
  const redirect = history.location.search?.replace('?redirect=', '') ?? '/'
  const login = useLogin();
  const notify = useNotify();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  return <section className={classes.root}>
    <Card className={classes.card}>
      <CardContent className={classes.form}>
        <TextField
          required
          label="Email"
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          required
          label="Password"
          type="password"
          onChange={e => setPassword(e.target.value)}
        />
      </CardContent>
      <CardActions style={{justifyContent: 'flex-end', paddingRight: '2em'}}>
        <Button
          size="large"
          onClick={() => history.push(redirect)}
        >
          <span>Skip</span>
        </Button>
        <Button
          size="large"
          onClick={async () => {
            try {
              await login({ email, password });
              notify(`Welcome ${email}!`);
            } catch (e) {
              notify(e.statusText, 'warning');
            }
          }}
        >
          <span>Login</span>
        </Button>
      </CardActions>
    </Card>
    <Notification />
  </section>
}

const ThemedLogin: FC<any> = props => {
  const theme = useSelector((state: PfReduxState) => state.theme);
  const themeOptions = theme === 'light' ? pfLightTheme : pfDarkTheme;

  return <ThemeProvider theme={createMuiTheme(themeOptions)}>
    <Login {...props} />
  </ThemeProvider>;
}

export default ThemedLogin;