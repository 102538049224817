import React, { FC } from 'react';
import { FieldProps, ReferenceManyField, SimpleShowLayout, SingleFieldList, TextField } from 'react-admin';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import traits from '../../entities/traits';
import entityTraits from '../../entities/entity-traits';
import inflection from 'inflection';
import PfMarkdownField from './PfMarkdownField';

const useStyles = makeStyles((theme: Theme) => ({
  field: {
    display: 'flex',
    alignItems: 'center',
    '& > span:first-child': {
      marginRight: theme.spacing(2),
    },
    '& .MuiChip-root:not(:last-child)': {
      marginRight: theme.spacing(1),
    }
  }
}))

const TraitTooltip: FC<any> = props => {
  return <SimpleShowLayout record={props.trait}>
    <PfMarkdownField source="body" />
  </SimpleShowLayout>
};

const useTooltipClasses = makeStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    fontSize: 'medium',
    boxShadow: theme.shadows[20],
    '& p': {
      margin: 0
    }
  }
}));

const TraitChipField: FC<FieldProps> = props => {
  const history = useHistory();
  const tooltipClasses = useTooltipClasses();
  const { record } = props;
  const label = `${record?.trait?.name}${record?.value ? ' ' + record?.value : ''}`;

  return <Tooltip
    placement="right"
    title={<TraitTooltip trait={record?.trait} />}
    classes={tooltipClasses}
  >
    <Chip
      clickable={true}
      color="primary"
      label={label}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        history.push(`/${traits.name}/${record?.trait.id}/show`)
      }}
    />
  </Tooltip>
}

const PfNameAndTraitsField: FC<FieldProps & { entityType?: string }> = props => {
  const classes = useStyles();
  const { resource, entityType } = props;
  let entityTypeFilter;

  if (resource) {
    entityTypeFilter = entityType ?? `${inflection.capitalize(inflection.singularize(resource!))}Entity`;
  }

  return <span className={classes.field}>
    <TextField {...props} source="name" />
    {entityTypeFilter && <ReferenceManyField
      {...props}
      basePath={`/${entityTraits.name}`}
      reference={entityTraits.name}
      target="entityId"
      source="id"
      filter={{
        entityType: entityTypeFilter,
        $relations: ['trait']
      }}
    >
      <SingleFieldList linkType={false}>
        <TraitChipField />
      </SingleFieldList>
    </ReferenceManyField>}
  </span>;
}

export default PfNameAndTraitsField;