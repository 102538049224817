import React, { FC } from 'react';
import { EditProps, NumberInput, TextInput } from 'react-admin';
import PfEdit from '../../common/components/PfEdit';
import PfSimpleForm from '../../common/components/PfSimpleForm';
import PfActionsInput from '../../common/inputs/PfActionsInput';
import PfMarkdownInput from '../../common/inputs/PfMarkdownInput';

const FeatEdit: FC<EditProps> = props => (
  <PfEdit {...props}>
    <PfSimpleForm>
      <TextInput source="name" />
      <TextInput source="traits" />
      <NumberInput source="level" />
      <TextInput source="subtype" />
      <PfActionsInput source="actions" addLabel={true} />
      <PfMarkdownInput source="body" fullWidth />
      <TextInput source="source" />
    </PfSimpleForm>
  </PfEdit>
);


export default FeatEdit;