import { ResourceProps } from 'react-admin';
import DeityEdit from './DeityEdit';
import DeityList from './DeityList';
import DeityShow from './DeityShow';

const deites: ResourceProps = {
  name: 'deites',
  list: DeityList,
  edit: DeityEdit,
  show: DeityShow
};

export default deites;